import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card';

const ProjectCard = styled(Card)({
    maxWidth: 345,
    border: "none",
    boxShadow: "none",
    //backgroundColor: "white",
    backgroundColor: "#f5f8fc"
    // ABSTRACT COLORS LATER< THIS IS AN OFF WHITE

})

export { ProjectCard }